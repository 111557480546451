import './../App.scss';

const employment = [
    {
        role: 'Developer Advocate',
        company: 'Kestra',
        dates: 'April 2024 - Present',
        logo: 'kestra.svg',
        class: 'kestra',
        url: 'https://willr.uk/kestra'
    },
    {
        role: 'Program Manager',
        company: 'MLH Fellowship',
        dates: 'June 2020 - March 2024',
        logo: 'fellowship.svg',
        class: 'fellowship',
        url: 'https://willr.uk/fellowship'
    },
    {
        role: 'Director & Co-Founder',
        company: 'Hack Productions',
        dates: 'March 2020 - March 2021',
        logo: 'hp.png',
        class: 'hp',
        url: 'https://willr.uk/hackproductions'
    },
    {
        role: 'Platforms Internship',
        company: 'Arm Ltd',
        dates: 'Summer 2019',
        logo: 'arm.png',
        class: 'arm',
        url: 'https://willr.uk/arm'
    },
    {
        role: 'RYA Dinghy Instructor',
        company: 'Paxton Lakes Sailing Club',
        dates: '2016 - 2019',
        logo: 'plsc.gif',
        class: '',
        url: 'https://willr.uk/plsc'
    }
];

const volunteering = [
    {
        role: 'Committee Member',
        company: 'RS600 Class Association',
        dates: '2023 - Present',
        logo: 'rs600.png',
        class: 'rs600',
        url: 'https://willr.uk/rs600'
    },
    {
        role: 'Team Member',
        company: 'SigneT 60',
        dates: '2022 - 2023',
        logo: 'st60.png',
        class: 'st60',
        url: 'https://willr.uk/signet60'
    },
    {
        role: 'Founder',
        company: 'Hackathons for Schools',
        dates: '2019 - 2020',
        logo: 'h4s.png',
        class: 'h4s',
        url: 'https://willr.uk/h4s'
    },
    {
        role: 'Co Lead Organiser',
        company: 'Hack Quarantine',
        dates: '2020',
        logo: 'hq.png',
        class: 'hq',
        url: 'https://willr.uk/hq'
    },
    {
        role: 'Organiser',
        company: 'HackTheMidlands',
        dates: '2019 - 2020',
        logo: 'htm.png',
        class: 'htm',
        url: 'https://willr.uk/htm'
    }
];

const education = [
    {
        course: 'BSc Computer Science',
        institute: 'University of Birmingham',
        dates: '2018 - 2021',
        logo: 'uob.png',
        class: 'uob',
        url: 'https://willr.uk/uob'
    }
];

const Home = () => {
    return (
        <div className="Home">
            <h2>Work</h2>
            <div className="card-container">
                
                {employment.map((item) => (
                    <a href={item.url}>
                        <div className={"card " + item.class}>
                            <div className="icon">
                                <img alt={'Company logo for ' + item.company} src={`/img/employment/${item.logo}`} />
                            </div>
                            <div className="info">
                                <p class="title">{ item.role }</p>
                                <p class="location">{ item.company }</p>
                                <p class="dates">{ item.dates }</p>
                            </div>
                        </div>
                    </a>
                ))}
            </div>

            <h2>Volunteering</h2>
            <div className="card-container">
                {volunteering.map((item) => (
                    <a href={item.url}>
                        <div className={"card " + item.class}>
                            <div className="icon">
                                <img alt={'Company logo for ' + item.company} src={`/img/volunteering/${item.logo}`} />
                            </div>
                            <div className="info">
                                <p class="title">{item.role}</p>
                                <p class="location">{item.company}</p>
                                <p class="dates">{item.dates}</p>
                            </div>
                        </div>
                    </a>

                ))}
            </div>
            <h2>Education</h2>
            <div className="card-container">
                {education.map((item) => (
                    <a href={item.url}>
                        <div className={"card " + item.class}>
                            <div className="icon">
                                <img alt={'Institute logo for ' + item.institute} src={`/img/education/${item.logo}`} />
                            </div>
                            <div className="info">
                                <p class="title">{item.course}</p>
                                <p class="location">{item.institute}</p>
                                <p class="dates">{item.dates}</p>
                            </div>
                        </div>
                    </a>

                ))}
            </div>
        </div>
    )
}

export { Home };
