
const Work = () => {
    return (
        <div>
            <div className='Bio'>
                <h1>Hi, I'm Will! 👋</h1>
                <p>I'm a Developer Advocate at <a href="https://kestra.io">Kestra</a>. Previously I built the <a href="https://fellowship.mlh.io">MLH Fellowship</a> from concept to 1200+ alumni over the course of 4 years after being involved with MLH hackathons for several years!</p>
                <p>Outside of work, I'm active within the UK sailing community, competing at events nationally in the RS600. I'm also a committee member for the RS600 Class Association, developing videos and organising open events! ⛵</p>
            </div>
            <br/>
            <br></br>
            <h2>Companies I've Worked With...</h2>
            <div className="BigClients">
                <img src={`/img/work/kestra.png`}></img>
                <img src={`/img/work/mlh.png`}></img>
                <img src={`/img/work/Meta-Logo.svg`}></img>
                <img src={`/img/work/github.png`}></img>
                <img src={`/img/employment/arm.png`}></img>
                <img src={`/img/work/google.svg`}></img>
            </div>
            {/* 
            <div className='Clients'>
                <img src={`/img/work/aws.svg`}></img>
                <img src={`/img/work/rbc.png`}></img>
                <img src={`/img/work/rssailing.png`}></img>
            </div>
            */}
            {/*
            <div className='Clients'>
                <img src={`/img/work/hackathonsuk.svg`}></img>
                <img src={`/img/work/hackquarantine.png`}></img>
                <img src={`/img/volunteering/htm.png`}></img>
                <img src={`/img/work/durhack22.png`}></img>
                <img src={`/img/work/durhack2022.png`}></img>
                <img src={`/img/work/hackumbc.png`}></img>
                <img src={`/img/work/ubhacking.png`}></img>
                <img src={`/img/work/htbvi.png`}></img>
                <img src={`/img/work/hn.png`}></img>
                <img src={`/img/work/covhack.png`}></img>
                <img src={`/img/work/durhack2019.png`}></img>
            </div>
            */}
            <h2>My Portfolio</h2>
            <h3>Videos</h3>
            <div className='Videos'>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/a2BZ7vOihjg?si=-ccRPZVAFbQZIJ1Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/cz50Jg1xspk?si=VWKaCO1YmfK7NUan" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/mrVfkGsDJ6o?si=fQ6mdX1Dy7RD014f" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/qdAM7-oSIUE?si=WTTPufhL5h-qQ2KP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
    
            <h3>Talks & Livestreams</h3>
            <div className='EventPhotos'>
                <img src={`/img/work/examples/hackcambridge.jpg`}></img>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/PBR3IONO1U0?si=62E6NbA8D8lvo-BP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/nsnit6wKIUU?si=AJWnjobXAuueYCee&amp;start=1784" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            
            {/*<h3>Blog Posts & Articles</h3>
            <div className='Blogs'>
                <LinkPreview url='https://kestra.io/blogs/2024-04-05-getting-started-with-kestra' width='560px' />
            </div>*/}

            <h3>Event Photography</h3>
            <div className="EventPhotos">
                <img src={`https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/CAM01935.jpg?alt=media&token=95bef05a-aff2-4f33-9798-8b8da8b85e70`}></img>
                <img src={`https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/CAM02713.jpg?alt=media&token=23761844-4035-43c8-adae-a4a7ff712dc5`}></img>
                <img src={`https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/CAM06057.jpg?alt=media&token=1a3c628f-6cce-49c4-bab2-c3a2d6e13a56`}></img>
            </div>
        </div>
    )
}

export { Work };
